<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div class="container">
				<div class="subindex_box_1">
					<span class="cell_group_tit">카르텔</span>
					<ul
						v-if="items_cartel.length > 0"
						class="settings_list"
					>
						<li
							v-for="(item, index) in list_cartel"
							:key="'item_' + index"
							class="settings_list_item"
						>
							<div class="cell_area">
								<div class="cell_tit" style="line-height: 120%">{{ item.cartl_name }}</div>
								<div class="cell_action">
									<p
										class="toggle-group"
										@click="postCartelPush(item)"
									>
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item.alram_fg == 'Y'}"
										><em class="hide">{{ item.cartl_name }}</em></span>
									</p>
								</div>
							</div>
						</li>
					</ul>
					<div
						v-else
					>
						<div class="list_none" style="overflow: hidden; margin-top: 50%">
							<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
							<span>가입된 카르텔이 없습니다.</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'mafia107'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '알림 설정'
					, title: '알림 설정'
					, type: 'mypage'
				}
				, items_cartel: []
				, type: this.$route.params.type ? this.$route.params.type : 'notice'
			}
		}
		, computed: {
			text_title: function(){
				let t = ''

				if(this.type == 'notice'){
					t = '공지글 알림설정'
				}else{
					t = '댓글 알림설정'
				}

				return t
			}

			,list_cartel: function(){
				
				return this.items_cartel.filter((item) => {
					if(this.type == 'notice'){
						item.alram_fg = item.notice_note_fg
					}else{
						item.alram_fg = item.comment_note_fg
					}
					return item
				})
			}
		}
		, methods: {
			getNotice: async function(){

				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_cartel_notice_alram
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.items_cartel = result.data.notice_cartl_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getComment: async function(){

				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_cartel_comment_alram
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.items_cartel = result.data.comment_cartl_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getData: function(){
				if(this.type == 'notice'){
					this.getNotice()
				}else{
					this.getComment()
				}

			}
			, postNoticePush: async function(item){

				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_notice_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: item.cartl_number
							, notice_note_fg: item.alram_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCommentPush: async function(item){

				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_comment_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: item.cartl_number
							, comment_note_fg: item.alram_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelPush: function(item){
				if(this.type == 'notice'){
					this.postNoticePush(item)
				}else{
					this.postCommentPush(item)
				}
			}
		}
		, created() {
			this.program.title = this.text_title
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>